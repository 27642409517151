/* You can add global styles to this file, and also import other style files */
@import "~animate.css/animate.min.css";

.material-icons {
  font-size: 30px;
  cursor: pointer;
}

.calenderButton {
  width: 2.75rem;
  background-image: url('/assets/icons/calender_icon.svg');
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}
